/* eslint-disable */

import http from "./http";
import store from "./store";

let heartbeatTimer: any;
const heartbeatInterval = 10000;

function startHeartBeat(socket: any) {
  heartbeatTimer = setInterval(function () {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ message: "heartbeat", command: 200, data: null }));
    }
  }, heartbeatInterval);
}

function stopHeartbeat() {
  clearInterval(heartbeatTimer);
}

export default {
  createWebSocket(userId: number, callback: any) {
    let ws: any = null;
    let that = this;

    //判断当前浏览器是否支持WebSocket
    if ("WebSocket" in window) {
      ws = new WebSocket(http.baseWS + "?userId=" + userId);

      //连接成功建立的回调方法
      ws.onopen = function (event: any) {
        console.log("ws连接成功!");

        // 在连接打开时开始心跳
        startHeartBeat(ws);
      };

      //接收到消息的回调方法
      ws.onmessage = function (message: any) {
        console.log("ws收到消息：" + message.data);
        callback(message);

        const msg = JSON.parse(message.data);
        if (msg.command === 201) {
          // console.log('ws收到心跳响应')
          ws.send(message.data);
        }
      };

      //ws连接断开的回调方法
      ws.onclose = function (e: any) {
        console.log("ws连接断开!");

        // 在连接关闭时停止心跳
        stopHeartbeat();

        setTimeout(() => {
          console.log("ws重连中...");
          that.createWebSocket(userId, callback);
        }, 2000); // 2秒后尝试重新连接
      };

      //连接发生错误的回调方法
      ws.onerror = function () {
        console.log("ws连接错误!");
      };
    } else {
      console.log("当前浏览器不支持websocket！");
    }

    return ws;
  },
};
