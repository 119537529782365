import { defineStore } from "pinia";
import { ref } from "vue";
import {
  getLocalStorage,
  localStorageContent,
} from "../views/AIGenerate/smartReading/commonMethod";

export const useEssayGenerateContentStore = defineStore("essayGenerateContent", () => {
  const content = ref("");
  const resData = ref();
  const contentObj = ref();

  async function storageContent(ct: any) {
    content.value = ct;
  }

  function clearContent() {
    if (content.value) content.value = "";
  }

  function storageIdResContent(cttObj: Record<string, any>) {
    contentObj.value = cttObj;
    if (cttObj?.answer) {
      localStorageContent("GenerateContent", {
        ...cttObj,
        hasCompleted: true,
      });
    } else {
      localStorageContent("GenerateContent", {
        ...cttObj,
        hasCompleted: false,
      });
    }
  }

  function getStorageData() {
    const obj = getLocalStorage("GenerateContent", {});
    contentObj.value = obj;
    return obj;
  }

  return {
    content,
    resData,
    contentObj,
    storageContent,
    clearContent,
    storageIdResContent,
    getStorageData,
  };
});
