import { createRouter, createWebHistory } from "@ionic/vue-router";
import type { RouteRecordRaw } from "vue-router";

import TeacherTabs from "../views/TeacherTabs.vue";
import StudentTabs from "../views/StudentTabs.vue";
import MergeTabs from "../views/merge/MergeTabs.vue";
import Register from "../views/Register.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/Login",
  },

  //登录
  {
    path: "/Login",
    component: () => import("@/views/Login.vue"),
  },

  {
    path: "/TeacherScheduleProfile",
    component: () => import("@/views/personal/TeacherScheduleProfile.vue"),
  },

  //注册
  {
    path: "/Register",
    component: Register,
  },

  //补充完善信息
  {
    path: "/Supplement",
    component: () => import("@/views/Supplement.vue"),
  },

  //用户反馈页
  {
    path: "/StudentFeedback",
    component: () => import("@/views/other/StudentFeedback.vue"),
  },

  //单词速记
  //speed
  {
    path: "/speed/Review/:studentId/:mastery",
    component: () => import("@/views/speed/Review.vue"),
    props: true,
  },

  //reading
  {
    path: "/reading/Index",
    component: () => import("@/views/reading/Index.vue"),
    children: [
      //陪练备课，阅读理解列表
      {
        path: "/reading/IndexPrepare",
        component: () => import("@/views/reading/IndexPrepare.vue"),
        props: true,
      },
    ],
  },

  {
    path: "/listening/Index",
    component: () => import("@/views/listening/Index.vue"),
  },

  //强行提交练习结果
  {
    path: "/AfterTest/:studentId",
    component: () => import("@/views/speed/AfterTest.vue"),
    props: true,
  },

  //合并
  {
    path: "/merge",
    redirect: "/MergeIndex",
    component: MergeTabs,
    children: [
      //首页
      {
        path: "/MergeIndex",
        component: () => import("@/views/merge/MergeIndex.vue"),
        props: true,
      },

      //练习记录
      {
        path: "/MergeClassRecord",
        component: () => import("@/views/merge/MergeClassRecord.vue"),
        props: true,
      },

      //个人中心
      {
        path: "/MergePersonalIndex",
        component: () => import("@/views/merge/MergePersonalIndex.vue"),
      },
    ],
  },

  //陪练
  {
    path: "/teacher",
    redirect: "/TeacherIndex",
    component: TeacherTabs,
    children: [
      //陪练首页
      {
        path: "/TeacherIndex",
        component: () => import("@/views/TeacherIndex.vue"),
        // component: () => import('@/views/speed/CutBox.vue'),
        props: true,
      },

      //学员管理
      {
        path: "/StudentList",
        component: () => import("@/views/other/StudentList.vue"),
        props: true,
      },

      //练习记录
      {
        path: "/ClassRecord",
        component: () => import("@/views/other/ClassRecord.vue"),
        props: true,
      },

      //个人中心
      {
        path: "/TeacherPersonalIndex",
        component: () => import("@/views/personal/TeacherPersonalIndex.vue"),
      },
      {
        // 教练设置
        path: "/TeacherSetting/:version",
        component: () => import("@/views/personal/TeacherSetting.vue"),
        props: true,
      },
      {
        // 教练个人中心
        path: "/TeacherPersonalCenter",
        component: () => import("@/views/personal/TeacherInfo.vue"),
        props: true,
      },
      {
        // 教练休息时间一览表
        path: "/teacherSchedule",
        component: () => import("@/views/personal/TeacherSchedule.vue"),
        props: true,
      },
      {
        // 教练休息申请
        path: "/teacherRestApply",
        component: () => import("@/views/personal/TeacherRestApply.vue"),
        props: true,
      },
      {
        // 语法模板
        path: "/grammarTemplate",
        component: () => import("@/views/grammar/GrammarTemplate.vue"),
        props: true,
      },
    ],
  },

  {
    path: "/student",
    component: StudentTabs,
    children: [
      {
        path: "/StudentIndex",
        component: () => import("@/views/StudentIndex.vue"),
      },
      {
        path: "/StudentRanking",
        component: () => import("@/views/other/StudentRanking.vue"),
        props: true,
      },
      {
        path: "/PersonalClassRecord",
        component: () => import("@/views/personal/PersonalClassRecord.vue"),
        props: true,
      },
      {
        path: "/Progress",
        component: () => import("@/views/personal/Progress.vue"),
        props: true,
      },
      {
        path: "/StudentPersonalIndex",
        component: () => import("@/views/personal/StudentPersonalIndex.vue"),
        props: true,
      },
    ],
  },

  {
    path: "/personal/MyLessonsInfo",
    component: () => import("@/views/personal/MyLessonsInfo.vue"),
    props: true,
  },

  // ai
  {
    path: "/ai-generate/:value",
    component: () => import("@/views/AIGenerate/AIGenerate.vue"),
  },

  // ai 智阅
  {
    path: "/aiGenerate/smartReading/:studentInfo",
    name: "smartReading",
    component: () => import("@/views/AIGenerate/smartReading/smartReading.vue"),
  },

  // ai 短文列表
  {
    path: "/aiGenerate/assayList",
    name: "assayList",
    component: () => import("@/views/AIGenerate/essayList/EssayList.vue"),
  },

  // ai 短文生成
  {
    path: "/aiGenerate/assayGenerate/:studentInfo",
    name: "assayGenerate",
    component: () => import("@/views/AIGenerate/smartReading/newEssayGenerate.vue"),
  },
  // ai 智阅单词 & 短文生成
  {
    path: "/aiGenerate/SmartReadingWordModal/:studentInfo",
    name: "SmartReadingWordModal",
    component: () => import("@/views/AIGenerate/smartReading/SmartReadingWordModal.vue"),
  },
  // ai智典
  {
    path: "/ai-generate/dictionary",
    name: "SmartDictionary",
    component: () => import("@/views/AIGenerate/smartDictionary/smartDictionary.vue"),
  },
  // ai智典
  {
    path: "/ai-generate/tuling",
    name: "TuLing",
    component: () => import("@/views/AIGenerate/TuLing/TuLing.vue"),
  },
  // ai智析
  {
    path: "/ai-generate/analysis",
    name: "SmartAnalysis",
    component: () => import("@/views/AIGenerate/smartAnalysis/smartAnalysis.vue"),
  },
  // ai智写
  {
    path: "/ai-generate/essay",
    name: "SmartEssay",
    component: () => import("@/views/AIGenerate/smartEssay/smartEssay.vue"),
  },
  // ai客服
  {
    path: "/ai-generate/customer-service",
    name: "CustomerService",
    component: () => import("@/views/AIGenerate/customerService/customerService.vue"),
  },
  // ai智听
  {
    path: "/ai-generate/smart-listen",
    name: "smartListen",
    component: () => import("@/views/AIGenerate/smartListen/SmartListen.vue"),
  },
  // ai智听答题页面
  {
    path: "/smart-listen-answer/:id",
    name: "smartListenAnswer",
    component: () => import("@/views/AIGenerate/smartListen/ListenToAnswer.vue"),
  },
  // ai智听的解析
  {
    path: "/smart-listen-analysis/:id",
    name: "smartListenAnalysis",
    component: () => import("@/views/AIGenerate/smartListen/ListeningAnalysis.vue"),
  },
  // ai智听的听力精选
  {
    path: "/smart-listen-featured",
    name: "smartListenFeatured",
    component: () => import("@/views/AIGenerate/smartListen/ListeningFeatured.vue"),
  },
  // ai视频
  {
    path: "/ai-generate/smart-video",
    name: "smartVideo",
    component: () => import("@/views/AIGenerate/smartVideo/smartVideo.vue"),
  },
  // ai智说
  {
    path: "/ai-generate/smart-speak",
    name: "smartSpeak",
    component: () => import("@/views/AIGenerate/smartSpeak/smartSpeak.vue"),
  },
  // ai词根词缀智典
  {
    path: "/ai-generate/root-dictionary",
    name: "SmartRootDictionary",
    component: () => import("@/views/AIGenerate/smartRootDictionary/smartRootDictionary.vue"),
  },
  // 学生端购买服务
  {
    path: "/student-buy-serve",
    name: "StudentBuyServe",
    component: () => import("@/views/other/StudentBuyServe.vue"),
  },
  // 学生端订单详情
  {
    path: "/student-order-detail",
    name: "StudentOrderDetail",
    component: () => import("@/views/other/StudentOrderDetail.vue"),
    props: true,
  },
  // 学生端我的订单
  {
    path: "/student-mine-order",
    name: "StudentMineOrder",
    component: () => import("@/views/other/StudentMineOrder.vue"),
    props: true,
  },
  // 听写单词
  {
    path: "/ai-generate/smart-dictate",
    name: "SmartDictate",
    component: () => import("@/views/AIGenerate/smartDictate/dictateBefore.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
