import EssayGenerateModal from "@/views/AIGenerate/smartReading/EssayGenerateModal.vue";
import { alertController, loadingController, modalController, toastController } from "@ionic/vue";

interface ModalParams {
  word?: any;
  type: string;
  title?: string;
  exerciseCountdown?: string;
  currentStep?: number;
  fold?: string;
  exerciseObj?: string;
  inputValues?: Record<string, any>[];
}

interface AlertParams {
  header?: string;
  subHeader?: string;
  message: string;
  buttons?: any[];
}

type ToastPosition = "top" | "middle" | "bottom";

/**
 * @param params - 参数 type - 弹框类型 type - 弹框类型 callback(data, role) - 回调函数
 * @param callback - 回调函数 关闭弹窗时的回调数据
 * */
export async function openEssayGenerateModal(params: ModalParams, callback?: any) {
  const modal = await modalController.create({
    component: EssayGenerateModal,
    componentProps: { ...params },
    cssClass: "half_modal_controller_overflow_visible_class",
    backdropDismiss: false,
    showBackdrop: false,
    initialBreakpoint: 1,
    breakpoints: [0.25, 0.5, 0.75, 1],
    backdropBreakpoint: 0.5,
  });
  await modal.present();
  modal.onDidDismiss().then(({ data, role }) => {
    if (callback) callback(data, role);
  });
}

/**
 * @param msg - 提示信息
 * @param position - 提示在视口位置 'top' | 'middle' | 'bottom'
 * @param duration - 提示停留时间
 * */
export async function openMsgToast(msg: string, position?: ToastPosition, duration?: number) {
  const toast = await toastController.create({
    message: msg,
    duration: duration || 1000,
    position: position || "middle",
    cssClass: "common_toast_controller_class",
  });
  await toast.present();
}

/**
 * @param params - alert组件参数 msg 必填
 * @param callback - 回调函数
 * */
export async function openProblematicAlert(params: AlertParams, callback?: any) {
  const alert = await alertController.create({
    ...params,
  });
  await alert.present();
  alert.onDidDismiss().then((res) => {
    if (callback) callback(res);
  });
  return alert;
}

export async function showLoading(
  msg: string,
  duration?: number,
  spinner?: string,
  backdropDismiss?: boolean
) {
  const loading = await loadingController.create({
    message: msg,
    duration: duration,
    spinner: "circles",
    backdropDismiss: backdropDismiss || true,
  });
  await loading.present();
  return loading;
}
