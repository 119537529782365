/* eslint-disable */

function set(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

function get(key: string) {
  return JSON.parse(<string>localStorage.getItem(key));
}

function remove(key: string) {
  localStorage.removeItem(key);
}

const STORAGE_KEY_TOKEN = "token";
const STORAGE_KEY_USERINFO = "userinfo";
const STORAGE_KEY_CURRENT_STUDENT = "currentStudent";
const STORAGE_KEY_CURRENT_STUDENT_ID = "currentStudentId";
const STORAGE_KEY_APPOINTMENT = "appointment";
const STORAGE_KEY_CLASS_TRIAL_ID = "CLASS_TRIAL_ID";
const STORAGE_KEY_TRIAL_COURSE_ID = "TRIAL_COURSE_ID";
const STORAGE_KEY_AFTER_TEST_FLAG = "STORAGE_KEY_AFTER_TEST_FLAG"; //是否需要学后检测
const STORAGE_KEY_OEM = "STORAGE_KEY_OEM";
const STORAGE_IS_MOBILE = "STORAGE_IS_MOBILE"; // 判断是否是移动端

export default {
  //###########################
  //sessionStorage
  clearStudentInfoSession() {
    localStorage.removeItem(STORAGE_KEY_CURRENT_STUDENT);
    localStorage.removeItem(STORAGE_KEY_CURRENT_STUDENT_ID);
    localStorage.removeItem(STORAGE_KEY_APPOINTMENT);
    localStorage.removeItem(STORAGE_KEY_CLASS_TRIAL_ID);
    localStorage.removeItem(STORAGE_KEY_TRIAL_COURSE_ID);
    localStorage.removeItem(STORAGE_KEY_AFTER_TEST_FLAG);

    localStorage.removeItem("GenerateContent"); //AI智阅所有相关文本内容
    // localStorage.removeItem("GLOSSARY_ARRAY");//AI智阅 生词本
    localStorage.removeItem("SCENE_LIST"); //AI智阅 生成的场景列表
    localStorage.removeItem("WORD_LIST"); //AI智阅 本地添加的单词列表
    sessionStorage.removeItem("aiReadingTime"); //Ai智阅 阅读时长
    sessionStorage.removeItem("aiReadingStatus"); //Ai智阅 阅读状态
    console.log("清理了用户临时数据。。。");
  },

  //清空Session
  clearSession() {
    const accountInfo = localStorage.getItem("user_accountInfo");
    const isRememberPsd = localStorage.getItem("is_remember_psd");
    const isReadAgreement = localStorage.getItem("is_read_agreement");
    sessionStorage.clear();
    localStorage.clear();
    accountInfo && localStorage.setItem("user_accountInfo", accountInfo);
    isRememberPsd && localStorage.setItem("is_remember_psd", isRememberPsd);
    isReadAgreement && localStorage.setItem("is_read_agreement", isReadAgreement);
    console.log("清理了全部用户数据。。。");
  },

  setToken(token: string) {
    localStorage.setItem(STORAGE_KEY_TOKEN, token);
  },

  getToken() {
    return localStorage.getItem(STORAGE_KEY_TOKEN);
  },

  //###############   OEM ################
  setOEMConfig(mapObj: object) {
    return localStorage.setItem(STORAGE_KEY_OEM, JSON.stringify(mapObj));
  },

  getOEMConfig() {
    return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_OEM));
  },

  getOEMUrl() {
    const oemConfig = this.getOEMConfig();
    if (oemConfig) {
      return oemConfig.OEM_RESOURCES_BASE + oemConfig.OEM_DETAIL_KEY_RESOURCES;
    }
    return null;
  },

  getOemBackgroundColor() {
    const oemConfig = this.getOEMConfig();
    if (oemConfig) {
      return oemConfig.OEM_DETAIL_KEY_BACKGROUND_COLOR;
    }
    return "#ffffff";
  },

  setUser(userinfo: object) {
    localStorage.setItem(STORAGE_KEY_USERINFO, JSON.stringify(userinfo));
  },

  getUser() {
    const user = JSON.parse(<string>localStorage.getItem(STORAGE_KEY_USERINFO));
    if (user) {
      return user;
    } else {
      alert("身份过期，请重新登录。");
      location.replace("/login");
    }
  },

  //获取当前用户
  getCurrentUser() {
    return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_USERINFO));
  },

  setStudent(student: object) {
    localStorage.setItem(STORAGE_KEY_CURRENT_STUDENT, JSON.stringify(student));
  },

  getStudent() {
    return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_CURRENT_STUDENT));
  },

  //保存正在上课的用户编号
  setStudentId(studentId: any) {
    localStorage.setItem(STORAGE_KEY_CURRENT_STUDENT_ID, studentId);
  },

  //获取正在上课的用户编号
  getStudentId() {
    return localStorage.getItem(STORAGE_KEY_CURRENT_STUDENT_ID);
  },

  //保存正在上课的预约信息
  setAppointment(appointment: any) {
    localStorage.setItem(STORAGE_KEY_APPOINTMENT, JSON.stringify(appointment));
  },

  //获取正在上课的预约信息
  getAppointment() {
    return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_APPOINTMENT));
  },

  //保存当前体验记录编号，等待结束时更新结束时间
  setTrialId(trialId: string) {
    localStorage.setItem(STORAGE_KEY_CLASS_TRIAL_ID, trialId);
  },

  //获取当前正在体验的记录编号，准备更新结束时间
  getTrialId() {
    return localStorage.getItem(STORAGE_KEY_CLASS_TRIAL_ID);
  },

  //保存当前体验资料编号
  setTrialCourseId(courseId: string) {
    localStorage.setItem(STORAGE_KEY_TRIAL_COURSE_ID, courseId);
  },

  //获取当前正在体验的资料编号
  getTrialCourseId() {
    return localStorage.getItem(STORAGE_KEY_TRIAL_COURSE_ID);
  },

  //当前正在学单词，需要学后检测
  setAfterTestFlag(value: string) {
    localStorage.setItem(STORAGE_KEY_AFTER_TEST_FLAG, value);
  },

  //判断当前是否在学单词
  getAfterTestFlag() {
    return localStorage.getItem(STORAGE_KEY_AFTER_TEST_FLAG);
  },

  //清理当前学词状态
  clearAfterTestFlag() {
    localStorage.removeItem(STORAGE_KEY_AFTER_TEST_FLAG);
    console.log("清理当前学词状态。。。");
  },

  //检查是否具有权限
  checkFunctions(funcNo: string) {
    if (this.getUser() && this.getUser().menus) {
      for (let i = 0; i < this.getUser().menus.length; i++) {
        if (funcNo === this.getUser().menus[i]) {
          return true;
        }
      }
    }
    return false;
  },

  isMobile() {
    const win: any = window;
    if (
      win.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      return true;
    } else {
      return false;
    }
  },
};
