import axios from "axios";
import { alertController } from "@ionic/vue";
import { Toast } from "@ionic-native/toast";
import { FileOpener } from "@ionic-native/file-opener";
import { Downloader, NotificationVisibility } from "@ionic-native/downloader";
import { AppVersion } from "@ionic-native/app-version";
import myConfig from "./config";

//显示下载错误信息
async function showDownloadError(error: any) {
  const alert = await alertController.create({
    header: "错误提示",
    message: "升级文件下载失败！<br>" + error,
    backdropDismiss: false, //点击背景不关闭
    buttons: ["确定"],
  });
  await alert.present();
}

//显示下载错误信息
async function showFeedback(version: any) {
  const alert = await alertController.create({
    header: "提示",
    message: "当前已经是最新版本" + version,
    backdropDismiss: false, //点击背景不关闭
    buttons: [
      {
        text: "确定",
      },
    ],
  });
  await alert.present();
}

function showToast(msg: string, method: any) {
  Toast.show(msg, "2000", "center").subscribe((toast: any) => {
    console.log(toast);
    if (method) {
      setTimeout(function () {
        method();
      }, 3000);
    }
  });
}

function openApp(path: string) {
  FileOpener.showOpenWithDialog(path, "application/vnd.android.package-archive")
    .then(() => console.log("文件打开成功"))
    .catch((e) => console.log("文件打开失败", e));
}

function downloadApp(fileName: string, fileURL: string) {
  const request = {
    uri: fileURL,

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    title: myConfig.config.brand,
    //description: '专注中高考英语提分，聚焦中小学英语应试',
    visibleInDownloadsUi: true, //下载进度条
    notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
    destinationInExternalFilesDir: {
      dirType: "Downloads",
      subPath: fileName,
    },
  };

  showToast("开始下载安装包...", null);

  Downloader.download(request)
    .then((location) => {
      showToast("下载完成！", () => {
        openApp(location);
      });
    })
    .catch(async (error) => {
      await showDownloadError(error);
      console.error(error);
    });
}

//本地存储键
const STORAGE_KEY_VERSION_CODE = "STORAGE_VERSION_CODE";

export default {
  //清理本地存储的版本
  clearStorageVersion() {
    localStorage.removeItem(STORAGE_KEY_VERSION_CODE);
  },

  getLastVersion() {
    return new Promise((resolve) => {
      axios.post("/checkLastVersion").then(async (res) => {
        resolve(res);
      });
    });
  },

  //获取当前版本信息
  async checkVersion(feedback: boolean) {
    const appCode = await AppVersion.getVersionCode();
    let version = parseInt(String(appCode));

    const storageVersionCode = localStorage.getItem(STORAGE_KEY_VERSION_CODE);
    if (storageVersionCode != null) {
      if (parseInt(storageVersionCode) > version) {
        version = parseInt(storageVersionCode);
      }
    }

    const res: any = await this.getLastVersion();

    if (res.data.success) {
      //当前版本小于系统支持的最小版本，则强制升级
      if (version < res.data.minVersionCode) {
        const alert = await alertController.create({
          header: "版本更新",
          message: "请更新至最新版本:" + res.data.lastVersionName,
          backdropDismiss: false, //点击背景不关闭
          buttons: [
            {
              text: "确定",
              handler: () => {
                downloadApp(res.data.fileName, res.data.fileURL);
              },
            },
          ],
        });
        await alert.present();
      }

      //大于最小版本，小于最大版本提示升级
      else if (version < res.data.lastVersionCode) {
        const alert = await alertController.create({
          header: "更新提醒",
          message: "是否更新至新版本？" + res.data.lastVersionName,
          backdropDismiss: false, //点击背景不关闭
          buttons: [
            {
              text: "稍后提醒",
            },
            {
              text: "忽略此次更新",
              handler: () => {
                //把当前最高版本存储到本地，用于忽略更新检测
                localStorage.setItem(STORAGE_KEY_VERSION_CODE, res.data.lastVersionCode);
              },
            },
            {
              text: "立即升级",
              handler: () => {
                downloadApp(res.data.fileName, res.data.fileURL);
              },
            },
          ],
        });
        await alert.present();
      }

      //根据参数决定是否必须做出提示
      else {
        if (feedback) {
          await showFeedback(res.data.lastVersionName);
        }
      }
    }
  },
};
