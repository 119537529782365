import { defineStore } from "pinia";
import { ref } from "vue";
import http from "../js/http";

export const useGlossaryStore = defineStore("glossary", () => {
  const glossaryList: any = ref({});

  function getGlossaryList(studentId: number, courseType: number) {
    const params = {
      pageNum: 1,
      pageSize: 99999,
      studentId: studentId,
      courseType: courseType,
    };
    http.post("/readingVocabulary/queryReadingPersonalVocabulary", params).then((res) => {
      if (res.data.success) {
        glossaryList.value = [];
        res.data.page.list.forEach((item: Record<string, any>) => {
          glossaryList.value.push(item);
        });
      }
    });
  }

  return {
    glossaryList,
    getGlossaryList,
  };
});
