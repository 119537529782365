import { defineStore } from "pinia";
import { ref } from "vue";
import { default_step_title } from "../views/AIGenerate/smartReading/defaultData";
import { useExerciseCountdownStore } from "./useExerciseCountdown";

export const useCurrentStepStore = defineStore("currentStep", () => {
  const currentStep = ref(1);
  const currentModalTitle = ref("Questions");
  const processIsEnd = ref(false);
  const currentStepList = ref(default_step_title.slice(0, 5));
  const timerVisible = ref(true);

  // 初始化进度为1
  function initStepNum() {
    currentStep.value = 1;
    currentStepList.value = default_step_title.slice(0, 5);
    processIsEnd.value = false;
    currentModalTitle.value = "Questions";
    timerVisible.value = true;
  }

  function control3SecondsVisible() {
    timerVisible.value = false;
  }

  // 下一步
  function controlStepData() {
    if (currentStep.value < default_step_title.length - 1) {
      currentStep.value++;
    } else {
      processIsEnd.value = true;
    }
  }

  // 回退 上一步
  async function goBackLastStep(closeFn: any, openFn: any) {
    const countdownStore = useExerciseCountdownStore();
    // 步骤
    if (currentStep.value > 1) {
      currentStep.value--;
    }
    // 最后一步时取消结束状态
    if (currentStep.value === default_step_title.length - 2) {
      processIsEnd.value = false;
    }

    // 控制当前流程的label显示
    if (currentStep.value === 4) {
      currentStepList.value = default_step_title.slice(0, 5);
    } else if (currentStep.value === 7) {
      currentStepList.value = default_step_title.slice(3, 8);
    }
    await handleProgressStepEvent(closeFn, openFn, "previous");

    if (currentStep.value === 2) {
      // 当回退到泛读答题和
      countdownStore.startCountdownFun();
    }
  }

  function handleProgressTitleNameArr() {
    // 展示优化
    if (currentStep.value === 5) {
      currentStepList.value = default_step_title.slice(3, 8);
    } else if (currentStep.value === 8) {
      currentStepList.value = default_step_title.slice(6, 10);
    }
  }

  async function handleProgressStepEvent(closeFnc?: any, openFunc?: any, type?: string) {
    const countdownStore = useExerciseCountdownStore();
    const title = default_step_title[currentStep.value - 1]["title"];
    switch (currentStep.value) {
      case 1: // 泛读
        if (closeFnc) {
          closeFnc();
        }
        break;
      case 2: // 泛读答题
        if (openFunc) {
          openFunc();
        }
        currentModalTitle.value = "Questions";
        break;
      case 3: // 挑选生词
        closeFnc();
        countdownStore.changeCountdown(1000 * 5 * 60);
        currentModalTitle.value = title;
        break;
      case 4: // 逐句翻译
        closeFnc();
        break;
      case 5: // 段落大意
        if (type) {
          // 回退
          closeFnc();
        }
        break;
      case 6: // 全文大意
        closeFnc();
        currentModalTitle.value = title;
        break;
      case 9: // 知识点
        currentModalTitle.value = title;
        if (openFunc && !processIsEnd.value) {
          openFunc();
        }
        break;
      case 10: // 朗读文章
        closeFnc();
        countdownStore.terminateCountdownFun();
        break;
      case 7: // 再次作答
        if (openFunc) {
          openFunc();
        }
        currentModalTitle.value = title;
        break;
      case 8: // 查看解析
        // closeFnc()
        if (openFunc) {
          openFunc();
        }
        currentModalTitle.value = title;
        break;
      case 11: // 智阅结束
        closeFnc();
        currentModalTitle.value = title;
        break;
      default:
        return;
    }
  }

  function handleEndOfProcessEvent(func: any, closeFn: any) {
    closeFn();
    func();
  }

  return {
    currentStep,
    processIsEnd,
    currentStepList,
    currentModalTitle,
    timerVisible,
    initStepNum,
    controlStepData,
    goBackLastStep,
    handleProgressTitleNameArr,
    handleProgressStepEvent,
    handleEndOfProcessEvent,
    control3SecondsVisible,
  };
});
