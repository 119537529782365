import axios from "axios";
import { modalController } from "@ionic/vue";
import myConfig from "./config";
import PageDialog from "../components/PageDialog.vue";

//****************************************************
axios.defaults.baseURL = myConfig.config.API_ADDRESS;

//****************************************************

async function presentAlert(message: string) {
  // const alert = await alertController.create({
  //     header: '提示信息',
  //     message: message,
  //     buttons: ['确定'],
  //     backdropDismiss: false
  // });
  // return alert.present();
  const modal = await modalController.create({
    component: PageDialog,
    componentProps: { message },
    cssClass: "center_modal_controller_class3",
  });
  return modal.present();
}

axios.interceptors.response.use(
  async (success) => {
    if (success && success.data) {
      if (success.data.success) {
        if (success.data.message) {
          await presentAlert(success.data.message);
        }
      } else {
        if (success.data.message) {
          await presentAlert(success.data.message);
        }
      }
    } else {
      alert(success);
    }

    return success;
  },
  async (error) => {
    console.log(error);
    // alert("系统检测到网络连接不畅，请切换更佳的网络，点击“OK”继续练习！");
    // await presentAlert("权限不足！");
    //location.replace("/login");
    return;
  }
);

export default {
  baseUrl: axios.defaults.baseURL, //java地址
  printURL: myConfig.config.PRINT_ADDRESS + "/print/pc.html", //打印地址
  baseWS: myConfig.config.WSS_ADDRESS,
  aiUrl: myConfig.config.AI_ADDRESS,
  post(url: string, data: any, authorization?: string, headers?: any, params?: any) {
    let token = localStorage.getItem("token");
    if (token == null) {
      token = "";
    }
    axios.defaults.headers.common["Authorization"] = authorization ? authorization : token;
    return axios({
      method: "post",
      url: url,
      data,
      params,
      headers,
    });
  },

  get(url: string, params?: object) {
    let token = localStorage.getItem("token");
    if (token == null) {
      token = "";
    }
    axios.defaults.headers.common["Authorization"] = token;
    return axios.get(url, { params });
  },
};
