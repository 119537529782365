<template>
  <ion-page>
    <ion-content>
      <div class="canvas-bg">
        <canvas ref="canvas" class="canvas"></canvas>
        <img
          v-if="cancelVisible"
          class="canvas-bg_cancel"
          src="@/assets/close_icon.png"
          alt=""
          @click="cancelSweep"
        />
      </div>
      <div class="navbar" style="margin-bottom: 22px">
        <img class="nav-icon" src="../assets/back.png" alt="" @click="doBack" />
        注册
      </div>
      <div class="ipt-box transparent-box">
        <button class="sweep" @click="media()">扫一扫</button>
        <button class="sweep">
          <input type="file" accept=".jpg,.jpeg,.png,.PNG" @change="upload($event)" />从相册选择
        </button>
      </div>
      <div class="ipt-box">
        <img src="../assets/register1.png" alt="" />
        <ion-input
          v-model="dataForm.schoolNo"
          :disabled="isDisabled"
          class="input"
          type="text"
          placeholder="请输入机构编码"
          maxlength="20"
        ></ion-input>
      </div>
      <div class="ipt-box">
        <img src="../assets/register4.png" alt="" />
        <ion-select
          v-model="dataForm.userType"
          class="select"
          interface="popover"
          placeholder="请选择用户类型"
        >
          <ion-select-option :value="1">用户</ion-select-option>
          <ion-select-option :value="2">陪练</ion-select-option>
          <ion-select-option :value="3" disabled>咨询师</ion-select-option>
        </ion-select>
      </div>

      <!--推荐码-->
      <div class="ipt-box">
        <img src="../assets/register2.png" alt="" />
        <ion-input
          v-model.trim="dataForm.teacherReferrer"
          :disabled="isDisabled"
          class="input"
          type="text"
          placeholder="请输入推荐码"
          maxlength="20"
        >
        </ion-input>
      </div>

      <div class="ipt-box">
        <img src="../assets/register2.png" alt="" />
        <ion-input
          v-model.trim="dataForm.username"
          class="input"
          type="text"
          placeholder="请输入姓名"
          maxlength="20"
        >
        </ion-input>
      </div>

      <div class="ipt-box">
        <img src="../assets/register5.png" alt="" />
        <div class="ipt-box_identify">
          <div class="ipt-box_identify-item">
            <ion-item ref="identityInputRef" class="input-item">
              <ion-input
                v-model.trim="dataForm.identity"
                type="text"
                placeholder="请输入身份证号"
                maxlength="18"
                @ionInput="validate($event, identityReg, 'identityInputRef')"
              ></ion-input>
              <ion-note slot="error" class="input-item_note">
                <div class="note-txt">请输入正确格式的身份证号</div>
              </ion-note>
            </ion-item>
          </div>
        </div>
        <div v-if="isDisabled" class="ipt-box_camera">
          <svg
            t="1712561090939"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4251"
            width="32"
            height="32"
          >
            <path
              d="M928.016 543.909H95.984c-17.718 0-31.995-14.278-31.995-31.995s14.277-31.995 31.995-31.995h832.032c17.718 0 31.995 14.278 31.995 31.995s-14.277 31.995-31.995 31.995z m-95.984 384.107h-192.14c-17.717 0-31.994-14.277-31.994-31.994s14.277-31.995 31.994-31.995H831.86c17.718 0 31.995-14.277 31.995-31.995V672.06c0-17.717 14.277-31.994 31.994-31.994s31.995 14.277 31.995 31.994v159.973c0.172 52.809-43.003 95.984-95.812 95.984z m-480.091 0H191.968c-52.98 0-95.984-43.003-95.984-95.984V672.06c0-17.717 14.277-31.994 31.994-31.994s31.995 14.277 31.995 31.994v159.973c0 17.718 14.45 31.995 31.995 31.995H351.94c17.717 0 31.994 14.277 31.994 31.995 0 17.717-14.277 31.994-31.994 31.994z m-223.963-544.08c-17.717 0-31.994-14.278-31.994-31.995V191.968c0-52.98 43.003-95.984 95.984-95.984H351.94c17.717 0 31.994 14.277 31.994 31.994s-14.277 31.995-31.994 31.995H191.968c-17.546 0-31.995 14.45-31.995 31.995V351.94c0 17.717-14.277 31.994-31.995 31.994z m768.044 0c-17.718 0-31.995-14.278-31.995-31.995V191.968c0-17.546-14.277-31.995-31.995-31.995h-192.14c-17.717 0-31.994-14.277-31.994-31.995s14.277-31.994 31.994-31.994H831.86c52.98 0 95.984 43.003 95.984 95.984V351.94c0.172 17.717-14.105 31.994-31.822 31.994z"
              p-id="4252"
              fill="#8a8a8a"
            ></path>
          </svg>
          <input type="file" accept=".jpg,.jpeg,.png,.PNG" @change="cameraImage" />
        </div>
      </div>

      <!--拍照的照片-->
      <!--      <div class="ipt-box">-->
      <!--        <img :src="ocrUrl" id="imagePreview" alt="Image preview..."/>-->
      <!--      </div>-->

      <div class="ipt-box">
        <img src="../assets/login1.png" alt="" />
        <div class="ipt-box_identify">
          <div class="ipt-box_identify-item">
            <ion-item ref="phoneInputRef" class="input-item">
              <ion-input
                v-model.trim="dataForm.phone"
                type="text"
                placeholder="请输入手机号"
                maxlength="11"
                @ionInput="validate($event, phoneReg, 'phoneInputRef')"
              ></ion-input>
              <ion-note slot="error" class="input-item_note">
                <div class="note-txt">请输入正确格式的手机号</div>
              </ion-note>
            </ion-item>
          </div>
        </div>
      </div>
      <div class="ipt-box">
        <img src="../assets/login2.png" alt="" />
        <ion-input
          v-model.trim="dataForm.password"
          :type="type"
          placeholder="请输入密码"
          maxlength="8"
          ng-reflect-type="password"
          ng-reflect-placeholder="Password"
          class="input"
        >
        </ion-input>
        <img v-if="type == 'password'" src="../assets/login3.png" alt="" @click="type = 'text'" />
        <img v-else src="../assets/login4.png" alt="" @click="type = 'password'" />
      </div>
      <div class="ipt-box">
        <img src="../assets/register3.png" alt="" />
        <ion-input
          v-model="dataForm.smsCode"
          type="text"
          placeholder="请输入验证码"
          maxlength="4"
          class="input"
        >
        </ion-input>
        <div class="code-label" @click="getSMSCode">{{ RegCodeLabel }}</div>
      </div>
      <div class="btn" @click="onRegister">注册</div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonNote,
  IonItem,
} from "@ionic/vue";

import {
  personSharp,
  phonePortraitSharp,
  codeWorking,
  businessSharp,
  school,
  chevronBackOutline,
  informationOutline,
} from "ionicons/icons";

import { BRAND_IMAGES } from "@/js/brandImages.ts";
import { getQrUrl } from "@/js/qrcode";
import { openMsgToast } from "@/views/AIGenerate/openModal";
import { isJSON } from "@/js/common";
import { postImageToBaiduOcr } from "@/js/baiduOcrApi";
import http from "@/js/http";
import jsQR from "jsqr";
/* eslint-disable */
import "jimp";

export default {
  name: "Edu_Register",
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonNote,
    IonItem,
  },

  setup() {
    return {
      personSharp,
      phonePortraitSharp,
      codeWorking,
      businessSharp,
      school,
      chevronBackOutline,
      informationOutline,
    };
  },

  data() {
    return {
      type: "password",
      errorMsg: undefined,

      //###############  短信验证 - 开始 ##############
      remainTime: 60,
      tickTimer: undefined, //短信发送倒计时定时器
      RegCodeLabel: "获取验证码",
      sendButtonEnable: true, //发生验证码按钮是否可用
      //###############  短信验证 - 结束 ##############

      dataForm: {
        schoolNo: undefined,
        userType: undefined,
        teacherReferrer: undefined,
        username: undefined,
        identity: undefined,
        phone: undefined,
        password: undefined,
        smsCode: undefined,
        teacherId: undefined,
        userAddress: undefined,
        ethnicGroup: undefined,
        sex: undefined,
      },
      brandImages: BRAND_IMAGES,
      audio: Object,
      video: Object,
      cvsele: Object,
      canvas: Object,
      isAnimation: true,
      cancelVisible: false,
      timer: null,
      imgurl: "",
      imgurl2: "",
      result: "",
      ocrUrl: "",
      isDisabled: false,
      identityReg:
        /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/,
      phoneReg: /^1[3-9][0-9]{9}$/,
    };
  },

  methods: {
    validate(ev, reg, refName, val) {
      const value = ev ? ev.target.value : val ? val : "";

      this.$refs[refName].$el.classList.remove("ion-valid");
      this.$refs[refName].$el.classList.remove("ion-invalid");

      if (value === "") return;

      value.match(reg)
        ? this.$refs[refName].$el.classList.add("ion-valid")
        : this.$refs[refName].$el.classList.add("ion-invalid");
    },

    async changeImage(e) {
      const file = e.target.files[0];
      const resData = await getQrUrl(file);
      console.log(resData);
    },

    doBack() {
      if (this.tickTimer) {
        clearInterval(this.tickTimer);
      }
      history.go(-1);
    },

    onChangeUserType() {
      this.dataForm.identity = undefined;
    },

    getSMSCode() {
      const that = this;
      if (!this.checkPhone()) {
        this.alert(this.errorMsg);
        return;
      }
      if (!this.sendButtonEnable) return;
      this.ajax.post("/sendRegisterCode", this.dataForm).then((res) => {
        if (res.data.success) {
          this.remainTime = 60;
          this.sendButtonEnable = false;
          that.tickTimer = setInterval(function () {
            that.remainTime--;
            that.RegCodeLabel = "获取（" + that.remainTime + "）";
            console.log(new Date().toLocaleString());
          }, 1000);

          //设定60秒解除锁定，以防连续发送
          setTimeout(function () {
            clearInterval(that.tickTimer);
            that.RegCodeLabel = "获取";
            that.sendButtonEnable = true;
          }, 1000 * 60);
          this.alert("验证码已发送，请注意查收！");
        }
      });
    },

    onRegister() {
      // 身份证校验失败不允许执行
      if (this.dataForm.identity) {
        if (!this.dataForm.identity.match(this.identityReg)) {
          return;
        }
      }

      // 手机号校验失败不允许继续执行
      if (this.dataForm.phone) {
        if (!this.dataForm.phone.match(this.phoneReg)) {
          return;
        }
      }

      if (this.dataForm.userType == undefined) {
        this.alert("用户类型不能为空！");
        return false;
      }

      if (!this.checkUsername()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkPhone()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkPassword()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkSMSCode()) {
        this.alert(this.errorMsg);
        return;
      }

      //机构不填，默认为总部
      if (this.dataForm.schoolNo == undefined) {
        this.dataForm.schoolNo = 9997;
      }

      this.dataForm.teacherId = this.userinfo ? this.userinfo.userId : null;
      this.ajax.post("/registerFromMobile", this.dataForm).then((res) => {
        if (res.data.success) {
          this.store.setToken(res.data.token);
          this.store.setUser(res.data.data);
          const uType = this.dataForm.userType;
          this.dataForm = {
            schoolNo: undefined,
            userType: undefined,
            username: undefined,
            phone: undefined,
            password: undefined,
          };
          this.alert("注册成功，您的账号为：" + res.data.data.account, function () {
            if (uType == 1) {
              location.replace("/StudentIndex");
            } else if (uType == 2) {
              location.replace("/TeacherIndex");
            }
          });
        }
      });
    },

    checkUsername() {
      const name = this.dataForm.username;
      if (name == undefined || name.trim() === "") {
        this.errorMsg = "姓名不能为空！";
        return false;
      }
      if (name.length > 20) {
        this.errorMsg = "姓名长度不能超过20个字符！";
        return false;
      }
      return true;
    },

    checkPhone() {
      const phone = this.dataForm.phone;
      if (phone == undefined || phone.trim() === "") {
        this.errorMsg = "手机号码不能为空！";
        return false;
      }

      if (phone.length > 11) {
        this.errorMsg = "手机号码不能超过11位！";
        return false;
      }

      if (!/^1\d{10}/.test(phone)) {
        this.errorMsg = "手机号码格式错误！";
        return false;
      }

      return true;
    },

    checkPassword() {
      const password = this.dataForm.password;
      if (password == undefined || password.trim() === "") {
        this.errorMsg = "密码不能为空！";
        return false;
      }

      if (password.length < 6 || password.length > 8) {
        this.errorMsg = "密码只能为6~8位任意！";
        return false;
      }
      return true;
    },

    //验证短信验证码
    checkSMSCode() {
      const smsCode = this.dataForm.smsCode;
      if (smsCode == undefined || smsCode.trim() === "") {
        this.errorMsg = "验证码不能为空！";
        return false;
      }

      if (!/^\d{4}$/.test(smsCode)) {
        this.errorMsg = "验证码只能为4位数字！";
        return false;
      }
      return true;
    },

    cancelSweep() {
      console.log("点击了");
      this.cance();
    },

    cance() {
      this.cancelVisible = false;
      this.isAnimation = false;
      cancelAnimationFrame(this.timer);
      setTimeout(() => {
        this.cvsele.style.display = "none";
      }, 1000);
    },

    draw(begin, end) {
      this.canvas.beginPath();
      this.canvas.moveTo(begin.x, begin.y);
      this.canvas.lineTo(end.x, end.y);
      this.canvas.lineWidth = 3;
      this.canvas.strokeStyle = "red";
      this.canvas.stroke();
    },

    sweep() {
      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        const { videoWidth, videoHeight } = this.video;
        this.cvsele.width = videoWidth;
        this.cvsele.height = videoHeight;
        this.canvas.drawImage(this.video, 0, 0, videoWidth, videoHeight);
        try {
          const img = this.canvas.getImageData(0, 0, videoWidth, videoHeight);
          this.imgurl = img;
          const obj = jsQR(img.data, img.width, img.height, {
            inversionAttempts: "dontInvert",
          });
          if (obj) {
            const loc = obj.location;
            this.draw(loc.topLeftCorner, loc.topRightCorner);
            this.draw(loc.topRightCorner, loc.bottomRightCorner);
            this.draw(loc.bottomRightCorner, loc.bottomLeftCorner);
            this.draw(loc.bottomLeftCorner, loc.topLeftCorner);
            if (this.result != obj.data) {
              this.audio.play();
              this.result = obj.data;
              if (isJSON(this.result)) {
                const resData = JSON.parse(obj.data);
                this.dataForm.schoolNo = resData.schoolNo;
                this.dataForm.teacherReferrer = resData.account;
                if (resData.hasOwnProperty("schoolNo") && resData.hasOwnProperty("schoolNo")) {
                  this.isDisabled = true;
                }
              } else {
                openMsgToast("识别失败，请检查二维码是否正确！");
              }
              this.cance();
              console.info("识别结果：", obj.data);
            }
          } else {
            console.error("识别失败，请检查二维码是否正确！");
          }
        } catch (err) {
          console.error("识别失败，请检查二维码是否正确！", err);
        }
      }
      if (this.isAnimation) {
        this.timer = requestAnimationFrame(() => {
          this.sweep();
        });
      }
    },

    media() {
      this.cancelVisible = true;
      this.isAnimation = true;
      this.cvsele.style.display = "block";
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: "environment" },
          })
          .then((stream) => {
            this.video.srcObject = stream;
            this.video.setAttribute("playsinline", true);
            this.video.setAttribute("webkit-playsinline", true);
            this.video.addEventListener("loadedmetadata", () => {
              this.video.play();
              this.sweep();
            });
          })
          .catch((error) => {
            this.cance();
            alert("对不起：未识别到扫描设备!");
            console.log(error.code + "：" + error.name + "，" + error.message);
          });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          {
            video: { facingMode: "environment" },
          },
          (stream) => {
            this.video.srcObject = stream;
            this.video.setAttribute("playsinline", true);
            this.video.setAttribute("webkit-playsinline", true);
            this.video.addEventListener("loadedmetadata", () => {
              this.video.play();
              this.sweep();
            });
          },
          (error) => {
            this.cance();
            alert("对不起：未识别到扫描设备!");
            console.log(error.code + "：" + error.name + "，" + error.message);
          }
        );
      } else {
        if (
          navigator.userAgent.toLowerCase().match(/chrome/) &&
          location.origin.indexOf("https://") < 0
        ) {
          console.log(
            "获取浏览器录音功能，因安全性问题，需要在localhost 或 127.0.0.1 或 https 下才能获取权限！"
          );
        } else {
          this.cance();
          alert("对不起：未识别到扫描设备!");
        }
      }
    },

    upload(e) {
      this.cance();
      const file = e.target.files[0];
      const createObjectURL =
        window.createObjectURL || window.URL.createObjectURL || window.webkitURL.createObjectUR;
      this.result = "";
      this.imgurl = createObjectURL(file);

      const fReader = new FileReader();
      fReader.readAsDataURL(file); // Base64 8Bit字节码
      // fReader.readAsBinaryString(file);  // Binary 原始二进制
      // fReader.readAsArrayBuffer(file);   // ArrayBuffer 文件流
      fReader.onload = (e) => {
        this.imgurl2 = e.target.result;
        e.target.result &&
          Jimp.read(e.target.result)
            .then(async (res) => {
              const { data, width, height } = res.bitmap;
              try {
                const resolve = await jsQR(data, width, height);
                this.result = resolve.data;
                if (isJSON(this.result)) {
                  const resData = JSON.parse(resolve.data);
                  this.dataForm.schoolNo = resData.schoolNo;
                  this.dataForm.teacherReferrer = resData.account;
                  if (resData.hasOwnProperty("schoolNo") && resData.hasOwnProperty("schoolNo")) {
                    this.isDisabled = true;
                  }
                } else {
                  openMsgToast("识别失败，请检查二维码是否正确！");
                }
              } catch (err) {
                this.result = "识别失败，请检查二维码是否正确！";
              } finally {
                console.info("读取到的文件：", res);
              }
            })
            .catch((err) => {
              console.error("文件读取错误：", err);
            });
      };
    },

    handleOwnerGender(gender) {
      if (gender === "男") {
        return 1;
      } else if (gender === "女") {
        return 2;
      } else {
        return 0;
      }
    },

    initQrInfo() {
      this.audio = new Audio("/assets/audio/tone.mp3");
      this.video = document.createElement("video");
      this.cvsele = this.$refs.canvas;
      this.canvas = this.cvsele.getContext("2d");
    },

    cameraImage(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      const fd = new FormData();
      fd.append("file", file);

      // 邵总账号token，测试ocr用
      // const authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJjcmVhdGVkIjoxNzEyNzE3NzA2MTA3LCJzdWIiOiIxMTQ4MzUiLCJleHAiOjE3NDM4MjE3MTZ9.eio466zADomL2UUZrpJEIzazk8CdIOECnihTTzpTJK9fzSdT7_to0lD19vfJavddmfEiuJpm-f79oXFF24fPlA'
      // http.post('/ocr/identification?Authorization=' + authorization, fd)
      http.post("/ocr/identification", fd).then((res) => {
        if (res.data.code === 200) {
          const resData = res.data.data;
          this.dataForm.username = resData.name;
          this.dataForm.identity = resData.id;
          this.dataForm.userAddress = resData.addr;
          this.dataForm.ethnicGroup = resData.nationality;
          this.dataForm.sex = this.handleOwnerGender(resData.gender);
        } else {
          openMsgToast(res.data.error);
        }
      });
    },
  },

  mounted() {
    this.userinfo = this.store.getCurrentUser();
    this.store.clearSession();
    this.initQrInfo();
    // this.getBaiduOcrAccessToken()
  },
};
</script>

<style scoped lang="less">
.input-item {
  border: none !important;
  position: relative;
  height: 100%;
  overflow: visible !important;

  &::part(native) {
    --min-height: none;
    border: none;
    height: 100%;
    --background: transparent;
    --highlight-color-focused: transparent;
    --padding-start: 8px;
    --highlight-background: transparent;
    caret-color: #262626;
  }

  &_note {
    position: relative;
    width: 100%;

    .note-txt {
      position: absolute;
      top: 0%;
      left: -5%;
      width: 100%;
    }
  }
}

.sweep {
  position: relative;
  margin: 20px;
  padding: 0.8rem;
  width: 300px;
  cursor: pointer;
  color: white;
  background: #42b983;
  border: 1px solid #42b983;
  overflow: hidden;

  input {
    position: absolute;
    font-size: 100px;
    opacity: 0;
  }
}

.canvas-bg {
  position: relative;

  &_cancel {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 101;
    width: 2rem;
  }
}

.canvas {
  display: none;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

ion-content {
  --background: #fff;
}

.loginTopbg {
  height: 50vh;
  width: 100%;
  display: block;
  background-repeat: no-repeat;
}

.loginTopbg_yidianling {
  background: var(--loginTopBg);
}

.loginTopbg_lixiao {
  background: var(--loginTopBgLixiao);
}

.item {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  --inner-border-width: 0px;
  --border-width: 0;
  --background: #fff;

  overflow: hidden;
}

ion-button {
  font-size: inherit;
}

ion-item,
ion-input {
  font-size: 14px;
  --border: none;
}

ion-list,
ion-item,
ion-col {
  background: none;
}

.buttonDisable {
  --background: #ccc;
}

/* new UI */
.navbar {
  height: 44px;
  line-height: 44px;
  text-align: center;
  position: relative;
}

.nav-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
}

.ipt-box {
  width: 89.33vw;
  height: 44px;
  border-radius: 8px;
  background: #f5f5f5;
  margin: auto;
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &_identify {
    position: relative;
    width: 100%;
    height: 100%;

    &-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &_camera {
    width: 1.6rem;
    height: 1.6rem;
    overflow: hidden;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
    }

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      cursor: pointer;
    }
  }
}

.ipt-box img {
  width: 16px;
  height: 16px;
}

.transparent-box {
  background: transparent;
}

.input {
  line-height: 44px;
  flex: 1;
  height: 44px;
}

.select {
  line-height: 44px;
  flex: 1;
  height: 44px;
  font-size: 14px;
  padding-left: 10px;
}

ion-select-popover {
  width: 89.33vw !important;
}

.btn {
  width: 81.33vw;
  border-radius: 25px;
  height: 44px;
  line-height: 44px;
  background: #2bca9c;
  color: #fff;
  font-size: 17px;
  text-align: center;
  margin: 30px auto;
}

.code-label {
  color: #2bca9c;
  font-size: 14px;
}
</style>
