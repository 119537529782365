import store from "./store";
import http from "./http";
import myConfig from "./config";
let dataBuffer: any[] = [];

export let downloadUrl = "";
export default {
  //获取OEM配置
  getOEMResources(callback: any) {
    http.get(`/oem/getOEMResource?hostname=${myConfig.config.hostname}`).then((res) => {
      if (res.data.success) {
        store.setOEMConfig(res.data.dataMap);
        if (callback) {
          callback(res.data.dataMap);
        }
      }
    });
  },

  getResources() {
    const oemConfig = store.getOEMConfig();
    if (oemConfig) {
      document.title = oemConfig.OEM_DETAIL_KEY_BRAND_NAME;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById("shortcut").href = store.getOEMUrl() + "/admin/favicon.ico";
    }
  },
};

/* 火狐浏览器不可用 */
export async function playAudioFromStream(url: string, params: any) {
  if (!window.MediaSource) {
    console.error("The Media Source Extensions API is not supported.");
    return;
  }

  const mediaSource = new MediaSource();

  mediaSource.addEventListener("sourceopen", sourceOpen, { once: true });

  function sourceOpen() {
    // 假设服务端发送的是MP3格式的音频流，如需支持其他格式，请修改类型
    const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
    fetchAndAppendChunks(sourceBuffer);
  }

  async function fetchAndAppendChunks(sourceBuffer: any) {
    try {
      const response: any = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "content-type": "application/json" },
      });
      const reader = response.body.getReader();

      const pump = async () => {
        const { done, value } = await reader.read();
        if (done) {
          console.log("readed");
          mediaSource.endOfStream();
          downloadUrl = URL.createObjectURL(new Blob(dataBuffer, { type: "audio/mpeg" }));
          return;
        }
        sourceBuffer.appendBuffer(value);
        dataBuffer.push(value);
        if (sourceBuffer.updating || mediaSource.readyState !== "open") {
          await new Promise((resolve) => (sourceBuffer.onupdateend = resolve));
        }
        pump(); // Read the next chunk
      };

      pump();
    } catch (error) {
      console.error("Error fetching audio chunks", error);
      sourceBuffer.onupdateend = function () {
        if (mediaSource.readyState === "open") {
          mediaSource.endOfStream();
        }
      };
    }
  }

  return URL.createObjectURL(mediaSource);
}
async function getAiTokenEvent() {
  const res = await http.get("/Ai/getAiToken");
  if (res.data.code === 200) {
    return res.data.data;
  } else {
    return "";
  }
}
/**
 * @param audioStr 需要流式播放的内容
 * */
export async function audioPlayStream(audioStr: string, callback: any) {
  dataBuffer = [];
  const token = await getAiTokenEvent();
  const params = {
    userKey: token,
    serverKey: "",
    conversationId: new Date().getTime() * Math.random(),
    prompt: audioStr,
    userSubmitId: JSON.parse(localStorage.getItem("userinfo") || "{}").schoolId,
  };
  const apiUrl = `${http.aiUrl}/gpt/sseTextToSpeech`;

  if (navigator.userAgent.indexOf("Firefox") >= 0) {
    audioPlayStreamStr2(audioStr, (source: any, type: string) => {
      callback(source, type);
    });
    return;
  }
  const url = await playAudioFromStream(apiUrl, params);
  if (callback) {
    callback(url, "MEDIA_SOURCE");
  }
}

const win: any = window;
const audioContt = win.AudioContext || win.webkitAudioContext;

/* firefox浏览器可用 */
export async function audioPlayStreamStr2(audioStr: string, callback2?: any) {
  const audioContext: any = new audioContt();
  const token = await getAiTokenEvent();
  const param = {
    userKey: token,
    serverKey: "",
    conversationId: new Date().getTime() * Math.random(),
    prompt: audioStr,
    userSubmitId: JSON.parse(localStorage.getItem("userinfo") || "{}").schoolId,
  };
  const apiUrl = `${http.aiUrl}/gpt/sseTextToSpeech`;

  fetch(apiUrl, {
    method: "POST",
    body: JSON.stringify(param),
    headers: { "content-type": "application/json" },
  })
    .then((response: any) => {
      return response.arrayBuffer();
    })
    .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start();
      if (callback2) {
        callback2(audioContext, "AUDIO_CONTEXT");
      }
    })
    .catch((e) => console.error(e));
}

/* 判断是否是json格式 */
export function isJSON(str: string) {
  if (typeof str == "string") {
    try {
      const obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        //去除布尔类型等特殊情况
        return true; //字符串为JSON格式
      } else {
        return false;
      }
    } catch (e) {
      console.log("error：" + str + "!!!" + e);
      return false;
    }
  }
  console.log("It is not a string!"); //去除整形(数字)的情况
}

// 下载音频文件
export function downloadAudio(fileName?: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = downloadUrl;
  a.download = `${fileName && "audio"}.mp3`;
  a.click();
  window.URL.revokeObjectURL(downloadUrl);
  document.body.removeChild(a);
}
