import { defineStore } from "pinia";
import { ref } from "vue";

export const useExerciseCountdownStore = defineStore("exerciseCountdown", () => {
  const countdownTimer = ref();
  const countdownEnds = ref(true);
  const initNum = ref(1000 * 5 * 60); // 测试时候倒计时 5 分钟
  const testCountdown = ref();
  // 开始倒计时
  function startCountdownFun() {
    if (countdownTimer.value) {
      clearInterval(countdownTimer.value);
    }
    countdownEnds.value = false;
    countdownTimer.value = setInterval(() => {
      if (initNum.value >= 0) {
        testCountdown.value = new Date(initNum.value).toLocaleTimeString().slice(3);
        initNum.value = initNum.value - 1000;
      } else {
        countdownEnds.value = true;
        initNum.value = 1000 * 5 * 60;
        testCountdown.value = undefined;
        clearInterval(countdownTimer.value);
      }
    }, 1000);
  }

  function clearCountdownInterval() {
    clearInterval(countdownTimer.value);
  }

  function changeCountdown(num: number) {
    initNum.value = num;
  }

  // 提交之后倒计时清零 | 终止倒计时
  function terminateCountdownFun() {
    countdownEnds.value = true;
    clearInterval(countdownTimer.value);
  }

  return {
    testCountdown,
    countdownEnds,
    startCountdownFun,
    terminateCountdownFun,
    clearCountdownInterval,
    changeCountdown,
  };
});
