import { sheetTimeList, startTimeList } from "@/views/AIGenerate/smartReading/commonMethod";

// export const default_grade = [
//     'AI智阅小学初级', 'AI智阅小学中级','AI智阅小学高级',
//     'AI智阅初中初级','AI智阅初中中级', 'AI智阅初中高级',
//     'AI智阅高中初级', 'AI智阅高中中级','AI智阅高中高级'
// ]

export const default_grade = [
  {
    gradeId: [1],
    label: "AI智阅P1",
    key: "AI智阅小学初级",
    wordCount: 50,
    grade: "小学",
  },
  {
    gradeId: [1],
    label: "AI智阅P2",
    key: "AI智阅小学中级",
    wordCount: 50,
    grade: "小学",
  },
  {
    gradeId: [1],
    label: "AI智阅P3",
    key: "AI智阅小学高级",
    wordCount: 50,
    grade: "小学",
  },
  {
    gradeId: [1, 2],
    label: "AI智阅J1",
    key: "AI智阅初中初级",
    wordCount: 150,
    grade: "初中",
  },
  {
    gradeId: [1, 2],
    label: "AI智阅J2",
    key: "AI智阅初中中级",
    wordCount: 150,
    grade: "初中",
  },
  {
    gradeId: [1, 2],
    label: "AI智阅J3",
    key: "AI智阅初中高级",
    wordCount: 150,
    grade: "初中",
  },
  {
    gradeId: [1, 2, 3],
    label: "AI智阅S1",
    key: "AI智阅高中初级",
    wordCount: 250,
    grade: "高中",
  },
  {
    gradeId: [1, 2, 3],
    label: "AI智阅S2",
    key: "AI智阅高中中级",
    wordCount: 250,
    grade: "高中",
  },
  {
    gradeId: [1, 2, 3],
    label: "AI智阅S3",
    key: "AI智阅高中高级",
    wordCount: 250,
    grade: "高中",
  },
];

export const default_scene = [
  { label: "广场", checked: true },
  { label: "医院", checked: true },
  { label: "工厂", checked: true },
  { label: "酒吧", checked: true },
  { label: "餐馆", checked: true },
  { label: "健身房", checked: true },
  { label: "体育场", checked: true },
  { label: "游泳馆", checked: true },
  { label: "瑜伽室", checked: true },
  { label: "公交站", checked: true },
  { label: "铁路", checked: true },
  { label: "机场", checked: true },
  { label: "超市", checked: true },
  { label: "旅行", checked: true },
  { label: "公园", checked: true },
  { label: "学校", checked: true },
];

export const default_gptParams = {
  aptmId: "",
  vocabulary: "",
  studentId: "",
  schoolId: "",
  courseId: "",
  promptParameter: {
    grade: "",
    theme: "",
  },
};

export const default_word_list = {
  pageNum: 1,
  pageSize: 45,
  sortProp: "",
  sortOrder: "",
  deliveryIds: [],
  childrenIds: "",
  hierarchy: "",
  studentId: "",
};

export const default_add_word_params = {
  aptmId: "",
  vocabulary: "",
  studentId: "",
  schoolId: "",
  courseId: "",
  promptParameter: {
    grade: "",
    theme: "",
  },
};

// 生成短文-泛读答题-挑选生词-逐句翻译-段落大意-全文大意-知识点-核对答案-朗读文章-二次答题-查看解析-朗读解析
export const default_step_title = [
  { title: "短文泛读", step: 1 },
  { title: "初次答题", step: 2 },
  { title: "挑选生词", step: 3 },
  { title: "逐句翻译", step: 4 },
  { title: "段落大意", step: 5 },
  { title: "全文大意", step: 6 },
  { title: "再次作答", step: 7 },
  { title: "查看解析", step: 8 },
  { title: "知识点", step: 9 },
  { title: "朗读文章", step: 10 },
  { title: "智阅结束", step: 11 },
];

/**
 * name 步骤状态对应步骤索引
 * key 步骤状态名
 * value 步骤所在位置索引
 * */
export const default_step_status_map: Record<string, number[]> = {
  question: [2, 3],
  contentCn: [4],
  paragraphCn: [5],
  textCn: [6, 7, 8, 9],
  knowledge: [10, 11],
};
export const default_exercise_answer = [
  { id: 1, value: "", autoFocus: false, checkedValue: "" },
  { id: 2, value: "", autoFocus: false, checkedValue: "" },
  { id: 3, value: "", autoFocus: false, checkedValue: "" },
  { id: 4, value: "", autoFocus: false, checkedValue: "" },
  { id: 5, value: "", autoFocus: false, checkedValue: "" },
];

export const default_word_list_obj = [
  { type: "小学", children: [] },
  { type: "初中", children: [] },
  { type: "高中", children: [] },
];

export const default_font_size_list = [{ name: "small" }, { name: "middle" }, { name: "big" }];

export const default_day = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  0: "日",
};

export const default_operate_buttons = [
  // {id: 1,label: '思维导图'},
  // {id: 2,label: '打印'},
  // {id: 3,label: '备课'},
  // {id: 4,label: '练习'},
  // {id: 5,label: '结束'},

  { id: 1, label: "结束" },
  { id: 2, label: "备课" },
  { id: 3, label: "思维导图" },
  { id: 4, label: "打印" },
  { id: 5, label: "练习" },
  { id: 6, label: "AI智阅" },
  { id: 7, label: "正在生成" },
];

// status 0 未排课 1 休息 2 上课
export const default_rest_sheet = [
  { row: "", col: sheetTimeList() },
  {
    row: "本周一",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周二",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周三",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周四",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周五",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周六",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
  {
    row: "本周日",
    col: new Array(36).fill({}).map(() => JSON.parse(JSON.stringify({ status: 0 }))),
  },
];

export const default_rest_apply_list = [
  {
    type: "datetime",
    icon: require("@/assets/calendar_icon.png"),
    label: "请选择日期",
    options: [],
    value: "",
    optionsVisible: false,
  },
  {
    type: "startTime",
    icon: require("@/assets/deep_grey_clock_icon.png"),
    label: "请选择开始休息时间",
    options: startTimeList(),
    value: "",
    optionsVisible: false,
  },
  {
    type: "endTime",
    icon: require("@/assets/deep_grey_clock_icon.png"),
    label: "请选择结束休息时间",
    options: startTimeList(),
    value: "",
    optionsVisible: false,
  },
];

export const default_edit_teacher_info = {
  basicInfo: [
    { label: "姓名", key: "username", value: "" },
    { label: "身份证", key: "identity", value: "" },
    { label: "性别", key: "sexText", value: "" },
    { label: "生日", key: "birthday", value: "" },
    { label: "手机", key: "phone", value: "" },
    { label: "邮箱", key: "email", value: "" },
    { label: "微信", key: "wechat", value: "" },
    { label: "紧急联系人", key: "emergencyContact", value: "" },
    { label: "紧急联系人手机", key: "emergencyContactPhone", value: "" },
    { label: "籍贯", key: "teacherBirthplace", value: "" },
    { label: "地址", key: "address", value: "" },
  ],
  eduInfo: [
    { label: "个人简介", key: "teacherResume", value: "" },
    { label: "学校", key: "teacherSchool", value: "" },
    { label: "年级", key: "teacherGrade", value: "" },
    { label: "班级", key: "teacherClass", value: "" },
    { label: "专业", key: "teacherSpecialty", value: "" },
  ],
};

export const default_Essay_Content = {
  eduAiAppointmentReadId: "",
  aptmId: "",
  vocabulary: "",
  content: "",
  contentCn: "",
  question: "",
  questionParse: "",
  answer: null,
  againAnswer: null,
  aiAnswer: "",
  knowledge: "",
  createTime: "",
  studentId: null,
  schoolId: null,
  courseId: null,
  createDate: null,
  promptParameter: "",
  audioStatus: null,
  teacherId: null,
  paragraphCn: "",
  textCn: "",
  status: null,
};

export const ai_dictionary_Regexp_list = [
  { cn: "英语例句", en: "liju" },
  { cn: "新概念", en: "xingainian" },
  { cn: "剑桥英语", en: "jianqiao" },
  { cn: "PTE", en: "pte" },
  { cn: "PET", en: "pet" },
  { cn: "单词", en: "danci" },
];

// 陪练问题
export const ai_questions_list_0: string[] = [
  "如何拉通复习？",
  "收藏夹单词如何删除？",
  "收藏夹单词图片",
  "个人中心中休息时间指的是什么？",
  "上课期间如遇系统卡顿导致学后检测未成功怎么办？",
  "上课期间如遇系统卡顿图片",
  "体验课用户报名了，会有多少佣金？",
  "阅读课正常创建抗遗忘，会有哪些内容？",
  "阅读课正常创建抗遗忘第一步",
  "阅读课正常创建抗遗忘第二步",
  "阅读课的抗遗忘只有单词没有知识点怎么办？",
  "请问网页突然无法下载pdf资料是什么情况？",
  "系统提示“练习失败，陪练时长不足，本节课需要扣除1.00个陪练时长！”怎么办？",
  "请问在哪里看用户的词汇量测试报告？",
  "查看词汇量测试报告图片",
  "用户在陪练服务过程中，整节课学习的单词记录不完整或者陪练提交的时候显示空白，怎么办？",
  "单词不完整图片",
  "该词库单词学习完了，九宫格上为什么还显示单词数量呢？",
  "每次的课前复习是从哪进入呢，是点击下面的开始复习还是点九宫格呢？",
  "请问抗遗忘是让用户自己一英一中，错了的用万能公式带读一次吗？",
  "门店老师后台排课后，陪练系统看不到小绿书怎么回事？",
  "陪练后的PDF资料打印出来出现串行的现象怎么办？",
  "用户或陪练，登录软件的时候一直提示密码错误怎么办？123456和88888888都尝试了也不行。",
  "一直提示密码错误的图片",
  "如何获取阅读课原文？",
  "是否可以使用手机进行陪练服务？",
  "如何创建抗遗忘？",
  "请问阅读课超时间了，没有生成抗遗忘怎么办呢？",
  "用户当前词库学完了，陪练应该怎么操作？",
  "用户当前词库学完图片",
  "阅读陪练后的评价模版",
  "陪练当节阅读课未上完、习题没来得及做，这种情况要怎么处理？",
  "抗遗忘的显示规律是什么？",
  "陪练端口如何查看学员的课时和陪练时长情况？",
  "陪练端口如何查看学员的课时和陪练时长情况的图片",
  "抗遗忘的时间可以更改吗？",
  "抗遗忘的时间图片",
  "陪练每月的薪资核对应该联系哪位老师？",
  "请问已在分支，推到总部的推荐奖怎么发？",
  "这个月门店老师没有添加复习方式1v1的标签，最近才添加上，对当月工资发放有影响吗？",
  "体验课报名的100奖金是每月15号统一发吗？",
  "每月15号发上个月全月的工资吗？是否会压半个月工资？",
  "体验课成功的补贴会包含在抗遗忘里面吗？",
  "请问1v1抗遗忘的补贴是只有排了课的那天有吗？没上课的那天也要抗遗忘，但这个抗遗忘是没有补贴对吗？",
  "实习证明应该找谁开？",
  "群昵称的什么老师专属是什么意思，怎么确定自己是哪位老师的陪练？",
  "怎么确定自己是哪位老师的陪练的图片",
  "学员总是不准时进会议怎么办，每天都要迟7.8分钟。",
  "请问怎么确定自己是哪位老师的陪练?",
  "学生注册的方式",
  "用手机登录后台页面显示不全",
  "完成度单词数量与累计学习单词数量不符合",
  "为什么之前学过的内容想复习但找不到了（超过21天了）",
  "开通了新词库学习，旧词库数据会消失吗？旧词库学新完了，没复习完怎么办",
  "学生如果每天都上课，怎么样合理安排：9宫格复习和21天抗遗忘复习",
  "阅读课程和单词课程教练端口可以自由切换吗",
  "完型和阅读中的stage表明什么",
  "词库里的单词学完后可以打印出来吗",
  "体验课可以指定词库体验吗",
  "阅读课的阅读可以自己选择上哪篇吗",
  "关于“阅读完型”、“解析语法”，电子文档能打印出来吗",
  "门店排的60分钟课程，但学员实际只上了30分钟课程",
  "为什么系统里的单词意思单一",
  "在学生信息里更改了手机号码，数据还会存在吗",
  "学生端安装包无法安装，显示有病毒，自动停止安装",
  "学生端的跟读功能在哪里",
  "教练端多出其他学生排课",
  "教练在教练端如何查看学生的单词进度",
  "教练APP有反馈渠道吗",
  "体验课孩子已经报名了，但是后台没有显示体验课成功是什么原因呢",
  "门店老师如何用手机端进行后台系统排课？",
  "词汇培训",
  "岗职培训",
  "阅读理解",
  "完型填空",
  "解析语法",
];

// 门店问题
export const ai_questions_list_1: string[] = [
  "如何拉通复习？",
  "抗遗忘中忘记的单词添加了收藏夹，是否可以复习这个收藏夹里面的单词？",
  "收藏夹单词如何删除？",
  "我有个用户上个月复习显示是1vN的标签，但其实是1v1的，目前还能改吗？",
  "上课期间如遇系统卡顿导致学后检测未成功怎么办？",
  "一节体验课有几次抗遗忘复习？",
  "阅读课正常创建抗遗忘，会有哪些内容？",
  "阅读课的抗遗忘只有单词没有知识点怎么办？",
  "请问网页突然无法下载pdf资料是什么情况？",
  "系统提示“练习失败，陪练时长不足，本节课需要扣除1.00个陪练时长！”怎么办？",
  "门店老师可以在哪里看到用户的测试报告？",
  "用户在陪练服务过程中，整节课学习的单词记录不完整或者陪练提交的时候显示空白，怎么办？",
  "该词库单词学习完了，九宫格上为什么还显示单词数量呢？",
  "请问抗遗忘是让用户自己一英一中，错了的用万能公式带读一次吗？",
  "门店老师后台排课后，陪练系统看不到小绿书怎么回事？",
  "陪练后的PDF资料打印出来出现串行的现象怎么办？",
  "用户或陪练，登录软件的时候一直提示密码错误怎么办？123456和88888888都尝试了也不行。",
  "门店老师是否可以查看系统后台的词库具体内容？",
  "门店老师如何上传词库内容？",
  "陪练没上课但是点开了小课本，这节课怎么处理呢？",
  "门店老师系统后台课程删除流程  ",
  "陪练因某种原因（系统问题或网络问题等）未上满课时，怎么处理呢？",
  "如何获取阅读课原文？",
  "30分钟软件时长如何进行兑换？",
  "PC版在使用中出现了报错，提示”应用程序中发生了未经处理的异常。如果单击继续，应用程序将忽略此错误并尝试继续。如果单击退出，应用程序将立即关闭。”怎么办？",
  "用户更换新陪练之后，怎么转移用户的抗遗忘数据？",
  "如何创建抗遗忘？",
  "体验课排课流程",
  "门店老师如何给用户充值软件时长、陪练时长和体验时长？",
  "门店老师后台排好了课程，但是没有上课，是否会消耗课时？门店老师应该如何操作呢？",
  "用户想连续上一节单词课和阅读课，能实现吗？",
  "请问如何申请词汇课二刷？二刷占用正课时长吗？",
  "陪练后台归属不在同一个中心，抗遗忘能否转移？",
  "门店老师在后台给用户排好的课程，在哪里可以查看？",
  "体验课可以指定词库进行体验吗？",
  "系统写作和口语什么时候上线？",
  "门店老师如何用手机端进行后台系统排课？",
  "半小时的课程和一小时课程，抗遗忘都是一样的规律复习吗？",
  "如何给自己的直属下级充值软件课时？",
  "如何查询当天学员学员排课信息？",
  "抗遗忘的显示规律是什么？",
  "由另一位教练带体验课，我带的正课，会有体验课奖金吗？",
  "请问学生没有上课，教练没有点小绿书，这种情况还会扣学生的课时么？",
  "用户在哪里可以看到单词学习的数据情况？",
  "用户如何打印之前学习的课程资料？",
  "后台已排好的课程，用户需要改时间了，怎么修改排课信息呢？",
  "不小心给用户多充值的陪练和软件时长，如何退费呢？",
  "家长注册显示机构编码错误，怎么解决？",
  "陪练一直在上单词课程，但是九宫格数据未推动，是什么情况呢？",
  "1万家长合伙人分享出去，每次都有3000元，还是只有一次？",
  "后台用户名字输入错误能更改吗？",
  "新人训有回放吗？",
  "采用教练优先的方排课（未选择课程和时间），查询不到教练",
  "后台预约排课时，时间、课程、教练名字都选择了，但是仍然不显示教练",
  "门店注册的学员可以删除吗",
  "如何给下级划拨课时",
  "门店需要修改体验中心和学员服务中心的密码",
  "60分钟课程如何兑换成30分钟课程",
  "课程预约时，学员为灰色，无法选择",
  "如何查看学生的剩余课时",
  "用手机登录后台页面显示不全",
  "教练端显示词库已经学完了，但是门店端显示还未学完",
  "账号一直登录不上（重复出现拼图验证）",
  "学生端登录进去课时显示为0，但实际还剩余较多课时，多次登录刷新课时仍然选择为0",
  "请问一个妈妈可以用同一部手机同时给两个孩子开通后台信息吗",
  "请问一个家里两个孩子，如果姐姐开了账号，可以给弟弟同时用吗",
  "一个手机号，两个孩子，只有一个APP，另一个孩子怎么用",
  "新版中考考纲是包含了小学考纲单词吗/新版高中考纲包含初中考纲吗",
  "推荐专属教练",
  "教练迟到或者旷课，怎么投诉",
  "临近上课，联系不上教练",
  "教练已经点进小书本，但学生一直未到，门店和教练联系家长未得到回复",
  "阅读课程和单词课程教练端口可以自由切换吗",
  "教练点错小书本怎么办",
];
